<template>
  <div class="card p-3 shadow-sm">
    <div class="row">
      <div class="col-12">
        <table class="small e-tbl">
          <thead class="bg-white" style="position: sticky; top: 0; z-index: 2">
            <tr>
              <th class="align-middle text-center" style="width: 1%" scope="col">#</th>
              <th class="align-middle text-center" style="width: 10%" scope="col">SHIP NAME</th>
              <th class="align-middle text-center" style="width: 5%" scope="col">FLAG</th>
              <th class="align-middle text-center" style="width: 10%" scope="col">SHIP TYPE</th>
              <th class="align-middle text-center" style="width: 25%" scope="col">MANAGEMENT</th>
              <th class="align-middle text-center" style="width: 1em" scope="col">FLEET</th>
              <th class="align-middle text-center" style="width: 30%" scope="col">SHIP BUILDER</th>
              <th class="align-middle text-center" style="width: 1em" scope="col">DATE OF BUILD</th>
              <th class="align-middle text-center" style="width: 1em" scope="col">AGE</th>
              <th class="align-middle text-center" style="width: 1em" scope="col">STATUS</th>
              <th class="align-middle text-center" style="width: 1em" scope="col">ACTION</th>
            </tr>
          </thead>
          <tbody>
            <tr class="text-uppercase" v-for="(sisterVessel, index) in sisterVessels" :key="sisterVessel.id">
              <td class="align-middle text-center">{{index + 1}}</td>
              <td class="align-middle text-center">{{sisterVessel.name | toUpper}}</td>
              <td class="align-middle text-center">{{!!sisterVessel.flag ? sisterVessel.flag.name : ''}}</td>
              <td class="align-middle text-center">{{!!sisterVessel.vessel_type ? sisterVessel.vessel_type.name : ''}}</td>
              <td class="align-middle text-center">{{!!sisterVessel.management_company ? sisterVessel.management_company.name : ''}}</td>
              <td class="align-middle text-center">{{!!sisterVessel.fleet ? sisterVessel.fleet.name : ''}}</td>
              <td class="align-middle text-center">{{!!sisterVessel.shipbuilder ? sisterVessel.shipbuilder : ''}}</td>
              <td class="align-middle text-center">{{!!sisterVessel.date_build ? dateEliteFormat(sisterVessel.date_build) : ''}}</td>
              <td class="align-middle text-center">{{!!sisterVessel.age ? sisterVessel.age : ''}}</td>
              <td class="align-middle text-center">{{!!sisterVessel.active ? 'ACTIVE' : 'INACTIVE'}}</td>
              <td class="align-middle text-center">
                <button class="e-btn e-btn-blue e-btn-sm e-text-white my-1"
                        @click="vesselOverview(sisterVessel.id)">
                  <font-awesome-icon icon="eye"/>
                  View
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import {DateService} from "@/services/DateService";
import vessel from "@/views/Vessel.vue";

export default {
  name: "VesselSisterShip",
  data(){
    return {

    }
  },
  computed:{
    ...mapGetters(['sisterVessels'])
  },
  methods:{
    ...mapActions(['getSisterVessels']),
    dateEliteFormat(date) {
      return DateService.dateEliteFormat(date);
    },
    vesselOverview(vesselId) {
       this.$router.push({
        name: "VesselParticular",
        params: {id: vesselId},
      });
    },
  },
  async created() {
    await this.getSisterVessels({'vessel_id': parseInt(this.$route.params.id)});
  }
}
</script>

<style scoped>

</style>
